exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-testing-game-js": () => import("./../../../src/pages/testing/game.js" /* webpackChunkName: "component---src-pages-testing-game-js" */),
  "component---src-pages-testing-mint-js": () => import("./../../../src/pages/testing/mint.js" /* webpackChunkName: "component---src-pages-testing-mint-js" */),
  "component---src-pages-testing-staking-js": () => import("./../../../src/pages/testing/staking.js" /* webpackChunkName: "component---src-pages-testing-staking-js" */)
}

